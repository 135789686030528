<template>
    <div id="Orders" class="m-0 p-0 row">

        <div class="col-12 d-flex flex-wrap p-0 m-0 justify-content-start justify-content-xl-end align-items-end py-2 py-xl-4">

            <div class="col-4 col-xxl-3 position-relative">
                <input @input="filterElements"
                    class="custom_input opensans-bold text-dark pl-3 w-100"
                    type="text"
                    v-model="search_query"
                    placeholder="Buscar"
                    />
                <img class="position-absolute"
                    style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                    :src="lupa_icon_b" />
            </div>
            <div class="col-4 col-xxl-3">
                <ns-select @change="filterElements"
                    theme="light"
                    class="opensans-bold text-dark"
                    label="Filtrar por:"
                    variant="white"
                    :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                    v-model="filter"
                    full
                    rounded>
                </ns-select>
            </div>
            <div class="col-4 col-xxl-3 px-2 px-xl-5">
                <button @click.stop="mural_modal = true;"
                    class="shadow_btn py-2 mr-3 w-100"
                    type="button">
                    Agregar Promoción
                </button>
            </div>
        </div>

        <div class="col-12" v-if="loading">
            <ns-spinner size="sm" variant="success"/>
        </div>

        <div class="col-12" v-if="empty_search && !search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con el filtro seleccionado.
            </p>
        </div>

        <div class="col-12" v-if="empty_search && search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con esos parámetros de búsqueda.
            </p>
        </div>

        <div class="col-12" v-if="!loading && !fetch_error && !empty_search && mural_table_data.length > 0">
            <ns-table :data="mural_table_data" 
                :allow_headers="mural_table_headers"
                color_headers="gray" color_headers_text="black" pagination_off
                color_body="white" border_body="white" color_body_text="black">
                <template v-slot:image="data">
                    <img :src="ns_logo" :alt="data.item.title"
                        style="width: 50px; height: 50px; object-fit: scale-down">
                </template>
                <template v-slot:date_init="data">
                    <span>
                        {{ data.item.date_init ? $informativeDate(data.item.date_init) : 'No definida' }}
                    </span>
                </template>
                <template v-slot:date_end="data">
                    <span>
                        {{ data.item.date_end ? $informativeDate(data.item.date_end) : 'No definida' }}
                    </span>
                </template>
                <template v-slot:creation="data">
                    <span>
                        {{ data.item.creation ? $informativeDate(data.item.creation) : 'No definida' }}
                    </span>
                </template>
                <template v-slot:active="data">
                    <span>
                        {{ data.item.active ? 'Activo' : 'Inactivo'}}
                    </span>
                </template>
                <template v-slot:actions="data">
                    <div class="row justify-content-center align-items-center ">
                        <div class="col-12 position-relative">
                            <button @click.stop="data.item.open = true"
                                class="w-100"
                                style="border: none; background: none;"
                                type="button">
                                Ver
                            </button>

                            <div @mouseleave="data.item.open = false"
                                class="position-absolute d-flex flex-column bg-white" 
                                style="width: 200px; height: 150px; z-index: 15; top: -5vh; right: 0vw;" v-if="data.item.open">

                                <button @click.stop="selectTableItem('detail', data.item.id_video)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Detalles
                                </button>

                                <button @click.stop="selectTableItem('edit', data.item.id_mural)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Editar
                                </button>

                                <button @click.stop="selectTableItem('delete', data.item.id_mural)" v-if="data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Borrar
                                </button>

                                <button @click.stop="selectTableItem('activate', data.item.id_mural)" v-if="!data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Activar
                                </button>
                            </div>
                        </div>

                        
                    </div>
                </template>
            </ns-table>
        </div>

        <div class="col-12 py-3" v-if="!fetch_error && !empty_search">
            <div class="row justify-content-evenly align-items-end">

                <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <span class="mr-3 opensans-bold text-dark">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 50px;"
                        v-model="rows"
                        class="custom_input text-center"
                        :disabled="page > 1"
                        />
                    
                    <ns-select @change="filterElements"
                        theme="light"
                        class="opensans-bold text-dark ml-2"
                        style="width: 180px;"
                        variant="white"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc"
                        full>
                    </ns-select>
                </div>



                <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-end pr-0 pr-xl-4 py-3 py-lg-0">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="custom_input text-center mx-4"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>
        <!----------- Modales  ------------->
        <ns-modal :show_modal="mural_modal" bg_color="white" :max_width="680"
            overlay_close @onClose="mural_modal = false">

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Título" 
                        theme="light"
                        v-model="title"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.title">
                        {{ errors.title }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Sección" 
                        theme="light"
                        v-model="redirect"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.redirect">
                        {{ errors.redirect }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Imagen Destacada
                    </label>
                    <ns-dropfile 
                        _id="image_1"
                        @charged="checkFile"
                        style="height: 255px;"
                        variant="success">
                    </ns-dropfile>
                    <span class="text-danger" v-if="errors.image">
                        {{ errors.image }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Preview
                    </label>
                    <div style="max-width:300px;">
                        <img class="img-fluid"
                        :src="preview_image" />
                    </div>
                </div>

                <div class="col-12 col-md-6 col-md-4 pr-4 pl-4">
                    <ns-input label="Fecha de inicio:" 
                        theme="light"
                        v-model="date_init"
                        type="date"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.date_init">
                        {{ errors.date_init }}
                    </span>
                </div>

                <div class="col-12 col-md-6 col-md-4 pr-4 pl-4">
                    <ns-input label="Fecha fin:" 
                        theme="light"
                        v-model="date_end"
                        type="date"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.date_end">
                        {{ errors.date_end }}
                    </span>
                </div>

                <div class="col-12 mt-4 montserrat-bold">
                    <div class="row p-0 justify-content-end align-items-center h-100">
                        <div class="col-12 col-md-6 d-flex justify-content-between p-0 px-3">
                            <button @click.stop="createEditElement"
                                class="btn btn-success"
                                style="border-radius: 15px;"
                                type="button">
                                Guardar
                            </button>
                            <button @click.stop="clearFields"
                                class="btn btn-danger"
                                style="border-radius: 15px;"
                                type="button">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            
        </ns-modal>

        <ns-modal :show_modal="delete_modal" bg_color="white" max_width="680"
            overlay_close @onClose="delete_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de eliminar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="inactivateElement"
                        class="btn btn-danger btn-pill"
                        type="button">
                        Eliminar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="active_modal" bg_color="white" max_width="680"
            overlay_close @onClose="active_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de activar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="activateElement"
                        class="btn btn-success btn-pill"
                        type="button">
                        Activar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>
    </div>
</template>

<script>
    import lupa_icon_b from 'ASSETS/icons/lupa_icon_b.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import ns_logo from 'ASSETS/images/natura_logo.png'
    import { mapGetters } from 'vuex'


    export default {
        data() {
            return {
                lupa_icon_b,
                arrow_left,
                ns_logo,
                mural_modal: false,
                delete_modal: false,
                active_modal: false,
                detail_modal: false,
                search_query: null,
                loading: true,
                fetch_error: false,
                empty_search: false,
                selected_id: null,
                mural_table_headers: [
                    {key: 'title', label: 'Título'},
                    {key: 'image', label: 'Imagen'},
                    {key: 'date_init', label: 'Fecha de incio'},
                    {key: 'date_end', label: 'Fecha de fín'},
                    {key: 'creation', label: 'Fecha de alta'},
                    {key: 'active', label: 'Estatus'},
                    {key: 'actions', label: 'Acciones'}
                ],
                mural_table_data: [],
                title: null,
                image: null,
                redirect: null,
                date_init: null,
                date_end: null,
                preview_image: null,
                errors: {
                    title: null,
                    image: null,
                    date_init: null,
                    date_end: null,
                    redirect: null
                },
                filter: 'all',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2'
            }
        },
        computed: {
            ...mapGetters({
                murals: 'murals/getMurals',
                mural: 'murals/getMural',
                last_page: 'murals/getLastPage',
                total_pages: 'murals/getTotalPages'
            })
        },
        methods: {
            //helpers.
            clearFields() {
             
                this.title = null
                this.image = null
                this.redirect = null
                this.date_init = null
                this.date_end = null
                this.preview_image = null

                this.mural_modal = false
                this.delete_modal = false
                this.active_modal = false
                this.detail_modal = false

                for(let error in this.errors) {
                    this.errors[error] = null
                }
            },
            checkFile(file){
                this.preview_image = file.url;
                this.image = file
                
            },
            async selectTableItem(type, id){
                //selecciona el id de un item, y despliega el modal necesario
                switch(type) {
                    case 'detail':
                        await this.$store.dispatch('murals/view', { id_mural: id})
                        this.detail_modal = true
                        break;
                    case 'edit':
                        this.selected_id = id
                        await this.$store.dispatch('murals/view', { id_mural: this.selected_id})
                        this.title = this.mural.title
                        this.redirect = this.mural.redirect
                        this.date_init = this.formatDate(this.mural.date_init)
                        this.date_end = this.formatDate(this.mural.date_end)
                        this.preview_image = this.mural.image
            
                        this.mural_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                    case 'activate':
                        this.selected_id = id
                        this.active_modal = true
                        break;
                    default:
                        console.info('Invalid case')
                        return
                }
            },
            async filterElements(){
                let search = ''
                this.loading = true
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('murals/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('murals/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('murals/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.murals === null) {
                    this.empty_search = true
                    this.loading = false
                    return
                }

                else {
                    this.empty_search = false
                    this.loading = false
                }
                
               this.mural_table_data = this.murals.map( mural => {
                    return {
                        ...mural,
                        actions: '-',
                        open: false
                    }
                })

            },
            //murals CRUD
            async createEditElement() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.title) || _.isEmpty(this.title)) {
                    this.errors.title = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.image) || _.isEmpty(this.image)) {
                    this.errors.image = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.date_init) || _.isEmpty(this.date_init)) {
                    this.errors.date_init = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.date_end) || _.isEmpty(this.date_end)) {
                    this.errors.date_end = '*Campo requerido'
                    complete = false
                }



                if(complete) {

                    if(this.selected_id) {
                        let payload = {
                            body: {
                                title: this.title,
                                image: this.image.file.name,
                                date_init: this.date_init,
                                date_end: this.date_end,
                                redirect: 'home'
                            },
                            query: {
                                id_mural: this.selected_id
                            }
                        }

                        await this.$store.dispatch('murals/update', payload)
                        this.clearFields()

                        this.filterElements()
                    }

                    else {
                        let payload = {
                            title: this.title,
                            image: this.image.file.name,
                            date_init: this.date_init,
                            date_end: this.date_end,
                            redirect: 'home'
                        }
                        
                        await this.$store.dispatch('murals/add', payload)
                        this.clearFields()
                        this.filterElements()
                    }
                }
            },
            async inactivateElement() {
                await this.$store.dispatch('murals/delete', { id_mural: this.selected_id, active: false})
                this.clearFields()
                this.filterElements()
            },

            async activateElement() {
                await this.$store.dispatch('murals/delete', { id_mural: this.selected_id, active: true})
                this.clearFields()
                this.filterElements()
            },
            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            try {
                //sets title for the view
                this.$store.commit('setTitle', 'PROMOCIONES')
                this.$store.commit('setIndex', 7)

                //list of videos
                await this.$store.dispatch('murals/list', {datatable: true, page: this.page, rows: this.rows, order_asc: false, active: true})

                if(this.murals) {
                    this.mural_table_data = this.murals.map( mural => {
                        return {
                            ...mural,
                            actions: '-',
                            open: false
                        }
                    })
                }
                
                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                console.error(error)
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style scoped>
    .custom_position {
        right: 10;
        
    }

    .custom_input {
        background-color: #e9ecef;
        border: none;
        height: 40px;
        border-radius: 10px;
    }

    @media screen and (max-width: 928px){
            .custom_position {
                right: 0;
            }
        }
</style>